import React from 'react';
import { IconProps } from 'models/Icon';
import _ from 'lodash';

const TimesIcon: React.FC<
  IconProps & { gradientColor?: 'purple' | 'pink' }
> = ({
  className,
  color = 'currentColor',
  gradient = false,
  gradientColor = 'pink',
}) => {
  const uniqeId = _.uniqueId();

  const isPurple = gradientColor === 'purple';

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.336 0.229221C1.03037 -0.0764069 0.534848 -0.0764069 0.229221 0.229221C-0.0764069 0.534848 -0.0764069 1.03037 0.229221 1.336L4.89345 6.00022L0.229664 10.664C-0.0759632 10.9696 -0.0759638 11.4652 0.229664 11.7708C0.535291 12.0764 1.03081 12.0764 1.33644 11.7708L6.00022 7.107L10.664 11.7708C10.9696 12.0764 11.4651 12.0764 11.7708 11.7708C12.0764 11.4651 12.0764 10.9696 11.7708 10.664L7.107 6.00022L11.7712 1.336C12.0768 1.03038 12.0768 0.534857 11.7712 0.22923C11.4656 -0.0763977 10.9701 -0.0763977 10.6644 0.22923L6.00022 4.89345L1.336 0.229221Z"
        fill={gradient ? `url(#${uniqeId}_paint0_linear_403_6731)` : color}
      />
      <defs>
        <linearGradient
          id={`${uniqeId}_paint0_linear_403_6731`}
          x1="0"
          y1="6"
          x2="12"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isPurple ? '#a761f5' : '#FF7BAC'} />
          <stop offset="1" stopColor={isPurple ? '#50a8fa' : '#D142F4'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default TimesIcon;
