import React from 'react';

type EyeProps = {
  className?: string;
  hide?: boolean;
} & React.SVGProps<SVGSVGElement>;

export default (props: EyeProps) => {
  const { hide, className } = props;

  return (
    <svg
      className={className}
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.33333C13.4455 2.33333 16.5182 4.28583 18.0182 7.375C16.5182 10.4642 13.4455 12.4167 10 12.4167C6.55455 12.4167 3.48182 10.4642 1.98182 7.375C3.48182 4.28583 6.55455 2.33333 10 2.33333ZM10 0.5C5.45455 0.5 1.57273 3.35083 0 7.375C1.57273 11.3992 5.45455 14.25 10 14.25C14.5455 14.25 18.4273 11.3992 20 7.375C18.4273 3.35083 14.5455 0.5 10 0.5Z"
        fill="url(#eye-icon-gradient-1)"
      />
      <path
        d="M10.0001 5.08332C11.2546 5.08332 12.2728 6.10998 12.2728 7.37498C12.2728 8.63998 11.2546 9.66665 10.0001 9.66665C8.74554 9.66665 7.72736 8.63998 7.72736 7.37498C7.72736 6.10998 8.74554 5.08332 10.0001 5.08332ZM10.0001 3.24998C7.74554 3.24998 5.90918 5.10165 5.90918 7.37498C5.90918 9.64832 7.74554 11.5 10.0001 11.5C12.2546 11.5 14.091 9.64832 14.091 7.37498C14.091 5.10165 12.2546 3.24998 10.0001 3.24998Z"
        fill="url(#eye-icon-gradient-2)"
      />

      {!hide && (
        <line
          x1="2"
          y1="12"
          x2="18"
          y2="2"
          stroke="var(--color-primary-pink)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      <defs>
        <linearGradient
          id="eye-icon-gradient-1"
          x1="0"
          y1="7.375"
          x2="20"
          y2="7.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--color-primary-pink)" />
          <stop offset="1" stopColor="var(--color-secondary-purple)" />
        </linearGradient>
        <linearGradient
          id="eye-icon-gradient-2"
          x1="5.90918"
          y1="7.37499"
          x2="14.091"
          y2="7.37499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="var(--color-primary-pink)" />
          <stop offset="1" stopColor="var(--color-secondary-purple)" />
        </linearGradient>
      </defs>
    </svg>
  );
};
