export const STORAGE_KEYS = {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken',
  PARTICIPANT: 'participant',
  SETTINGS_POPPED: 'customer-profile-settings-popped',
  EMAIL_TO_VERIFY: 'email-to-verify',
  WEB_PUSH_TOKEN: 'web-push-token',
};

export default {
  ram: {} as Record<string, string>,
  setItem(key: string, value: Record<string, any> | string, isSession = false) {
    try {
      if (isSession) {
        sessionStorage.setItem(key, JSON.stringify(value));
      } else localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: string, isSession = false): void {
    try {
      if (isSession) {
        sessionStorage.removeItem(key);
      } else localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: string, isSession = false): T {
    try {
      if (isSession) return JSON.parse(sessionStorage.getItem(key));

      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key]);
    }
  },
};
