import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement> & { showGradient?: boolean };

export default (props: IconProps) => {
  const { showGradient = true, ...restOfProps } = props;

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 6C11.5523 6 12 6.44772 12 7V10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H12V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V12H7C6.44772 12 6 11.5523 6 11C6 10.4477 6.44772 10 7 10H10V7C10 6.44772 10.4477 6 11 6Z"
        fill="url(#paint0_linear_7557_25389)"
      />
      {showGradient && (
        <defs>
          <linearGradient
            id="paint0_linear_7557_25389"
            x1="-3.66031e-08"
            y1="22"
            x2="22.3837"
            y2="21.6025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A761F5" />
            <stop offset="1" stopColor="#50A8FA" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};
