import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Input, { InputProps } from 'components/Input';
import EyeIcon from 'icons/Eye.icon';
import { checkPasswordStrength, MAX_PASSWORD_STRENGTH } from './utils';
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';
import formValidators from 'utils/formValidators';

import './PasswordField.styles.scss';

type PasswordFieldProps = {
  hidePasswordStrength?: boolean;
  hideIcon?: boolean;
} & InputProps &
  UseFieldConfig<string, string>;

type PasswordFieldComponentProps = InputProps &
  FieldRenderProps<string, HTMLElement>;

const PasswordFieldComponent: React.FC<PasswordFieldComponentProps> = (
  props,
) => {
  const {
    className,
    hideIcon = false,
    input,
    meta,
    hidePasswordStrength,
    ...rest
  } = props;

  const [strength, setStrength] = useState(0);
  const [hidePassword, setHidePassword] = useState(true);

  const eyeIcon = useMemo(
    () => (
      <button
        type="button"
        className="anys-password-field__eye"
        onClick={() => setHidePassword((prev) => !prev)}
      >
        <EyeIcon hide={hidePassword} />
      </button>
    ),
    [hidePassword],
  );

  const error = useMemo(() => formValidators.getErrorFromMeta(meta), [meta]);

  const testStrength = useCallback((password: string) => {
    const strength = checkPasswordStrength(password);
    setStrength(strength);
  }, []);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      testStrength(value);
      input.onChange(event);
    },
    [input, testStrength],
  );

  return (
    <div className={classNames('anys-password-field', className)}>
      <Input
        {...rest}
        {...input}
        {...error}
        className="anys-password-field__input"
        type={hidePassword ? 'password' : 'text'}
        onChange={onChange}
        suffixNode={hideIcon ? null : eyeIcon}
      />
      {!hidePasswordStrength && (
        <span className="anys-password-field__strength-info">
          Strength: {strength} (max: {MAX_PASSWORD_STRENGTH})
        </span>
      )}
    </div>
  );
};

const PasswordField: React.FC<PasswordFieldProps> = (props) => (
  <Field component={PasswordFieldComponent} {...props} />
);

export default PasswordField;
