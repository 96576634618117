import regex from 'constants/regex';

const PASSWORD_STRENGTH_REGEXES = [
  regex.LOWERCASE_LETTERS,
  regex.UPPERCASE_LETTERS,
  regex.NUMBER,
  regex.SPECIAL_CHAR,
  regex.MIN_LENGTH,
];

export const MAX_PASSWORD_STRENGTH = PASSWORD_STRENGTH_REGEXES.length;

export const checkPasswordStrength = (password: string) => {
  let strength = 0;

  PASSWORD_STRENGTH_REGEXES.forEach((regex) => {
    password.match(regex) && strength++;
  });

  return strength;
};
