import { useState, useRef, useEffect } from 'react';

const useHeightAnimation = (initiallyExpanded: boolean) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number | null>(null);

  useEffect(() => {
    if (isExpanded) {
      setContentHeight(contentRef.current?.scrollHeight || 0);
    } else {
      setContentHeight(0);
    }
  }, [isExpanded]);

  const toggleIsExpanded = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  return {
    isExpanded,
    toggleIsExpanded,
    contentRef,
    contentHeight,
  };
};

export default useHeightAnimation;
