import React from 'react';
import _ from 'lodash';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => {
  const gradientId = _.uniqueId();

  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66667 0.833496C6.1269 0.833496 6.5 1.20659 6.5 1.66683V2.0835H11.5V1.66683C11.5 1.20659 11.8731 0.833496 12.3333 0.833496C12.7936 0.833496 13.1667 1.20659 13.1667 1.66683V2.0835H16.0833C17.0038 2.0835 17.75 2.8297 17.75 3.75016V16.6668C17.75 17.5873 17.0038 18.3335 16.0833 18.3335H1.91667C0.9962 18.3335 0.25 17.5873 0.25 16.6668V3.75016C0.25 2.82969 0.996192 2.0835 1.91667 2.0835H4.83333V1.66683C4.83333 1.20659 5.20643 0.833496 5.66667 0.833496ZM4.83333 3.75016H1.91667V7.0835H16.0833V3.75016H13.1667V5.00016C13.1667 5.4604 12.7936 5.8335 12.3333 5.8335C11.8731 5.8335 11.5 5.4604 11.5 5.00016V3.75016H6.5V5.00016C6.5 5.4604 6.1269 5.8335 5.66667 5.8335C5.20643 5.8335 4.83333 5.4604 4.83333 5.00016V3.75016ZM16.0833 8.75016H1.91667V16.6668H16.0833V8.75016ZM4 10.8335C4 10.3733 4.3731 10.0002 4.83333 10.0002H7.33333C7.79357 10.0002 8.16667 10.3733 8.16667 10.8335C8.16667 11.2937 7.79357 11.6668 7.33333 11.6668H4.83333C4.3731 11.6668 4 11.2937 4 10.8335ZM9.83333 10.8335C9.83333 10.3733 10.2064 10.0002 10.6667 10.0002H13.1667C13.6269 10.0002 14 10.3733 14 10.8335C14 11.2937 13.6269 11.6668 13.1667 11.6668H10.6667C10.2064 11.6668 9.83333 11.2937 9.83333 10.8335ZM4 14.1668C4 13.7066 4.3731 13.3335 4.83333 13.3335H7.33333C7.79357 13.3335 8.16667 13.7066 8.16667 14.1668C8.16667 14.6271 7.79357 15.0002 7.33333 15.0002H4.83333C4.3731 15.0002 4 14.6271 4 14.1668ZM9.83333 14.1668C9.83333 13.7066 10.2064 13.3335 10.6667 13.3335H13.1667C13.6269 13.3335 14 13.7066 14 14.1668C14 14.6271 13.6269 15.0002 13.1667 15.0002H10.6667C10.2064 15.0002 9.83333 14.6271 9.83333 14.1668Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="0.25"
          y1="9.5835"
          x2="17.75"
          y2="9.5835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7BAC" />
          <stop offset="1" stopColor="#D142F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
