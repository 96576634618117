import React from 'react';
import { IconProps } from 'models/Icon';
import _ from 'lodash';

const ChevronIcon: React.FC<IconProps> = ({
  className,
  color = '#4CAFFB',
  gradient = false,
}) => {
  const uniqeId = _.uniqueId();

  return (
    <svg
      className={className}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id={`${uniqeId}_linear`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#FF7BAC" />
          <stop offset="100%" stopColor="#D142F4" />
        </linearGradient>
      </defs>
      <path
        d="M1 1.5L6 6.5L11 1.5"
        stroke={gradient ? `url(#${uniqeId}_linear)` : color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronIcon;
